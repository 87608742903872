.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


:root {
  --color-primary: #00CF79;
  --color-dark-primary: #004d48;
  --color-red: #FD625E;
  --color-neutral: #C1BABA;
  --color-yellow: #F2C80F;
  --color-orange: #ff9c39;
  --color-blue: #688DF4;
  --color-skin: #F5C5A2;
  --color-dark-blue: #28283c;
  --color-blue-card: #2C2C3E; 
  --color-blue-found: #23232E; 
}







* {
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Montserrat', sans-serif !important;
  background-color: var(--color-blue-found);
  overflow-x: hidden;
}
.color-primary {
  color: var(--color-primary);
}
.color-white {
  color: white;
}
.color-light-grey {
  color: #83868b;
}
.color-red {
  color: red;
}
.bg-white {
  background-color: white;
}
.bg-primary {
  background-color: var(--color-primary);
  color: white;
  display: inline-block;
  padding: 2px 10px;
  border-radius: 15px;
}
.m-auto {
  margin: auto;
}
.text-align-end {
  text-align: end;
}
.text-align-start {
  text-align: start;
}
.text-align {
  text-align: center;
}
.position-relative {
  position: relative;
}

// Report
.report {
  padding: 1rem;
  display: grid;
  grid-gap: 1rem;

  & > article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 6px;
  }
  .card {
    border-radius: 2rem;
  }
  .data-info .card {
    display: grid;
    grid-template-columns: 4fr 3fr;
    padding: .5rem;
    background-color: white;

    .description {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 3px;

      .client {
        font-weight: 500;
        text-transform: capitalize;
      }
      .text {
        font-weight: 800;
      }
    }
    .icon {
      display: flex;
      justify-content: flex-end;
      position: relative;
      width: 100%;
    }
    .icon > img {
      width: 100%;
    }
  }
  
  /* Plan */
  .data-info {
    
    .info-plan {
      display: grid;
      grid-template-columns: 1fr 3fr 4fr;
      align-items: center;
  
      .container-icon {
        width: 25px;
        height: 25px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        border-radius: 50%;

        & > img {
          height: 110%;
          width: 110%;
          margin: auto;
          margin-top: -5%;
        }
      }
      .plan {
        font-size: 10px;
        margin-right: 4px;
        
        & > span {
          font-weight: 700;
        }
      }
      .address {
        font-size: 6px;
        font-weight: 700;
        border-radius: 2rem;
        text-align: center;
      }
    }

    /* Services */
    .consume {
      background-color: var(--color-blue-card);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 4px;
      box-shadow: 0px 1px 10px 2px rgb(0 0 0 / 45%);

      .row {
        display: grid;
        grid-template-columns: 1fr 4fr .7fr;
        grid-gap: 3px;
        width: 100%;

        & > img {
          width: 60%;
          margin: auto;
        }
        .expired-invoice {
          font-size: 8px;
        }
        .header {

          .main-title {
            font-size: 7px;
            font-weight: 700;
            text-align: center;
          }
          .other-title {
            background-color: var(--color-primary);
            font-size: 4px;
            padding: 3px 0;
            color: white;
            border-radius: 20px;
            text-align: center;
            font-weight: 800;
          }
        }
        .col-service {
          padding: 1rem .5rem;
          font-size: 14px;
        }
      }
      .cols {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        align-items: center;
        grid-gap: 4px;

        .service {
          display: grid;
          grid-template-rows: 1fr 1fr;
          justify-content: center;
          align-items: center;
          height: 100%;
          margin: auto;

          .text {
            position: relative;
            width: 100%;
            text-align: center;
            font-size: 12px;

            .consume-service {
              display: flex;
              justify-content: center;
              width: 100%;
              position: absolute;
              top: 100%;
              font-size: 8px;
              font-weight: 500;
            }
          }
        }
        & > div {
          display: flex;
          height: 100%;
          margin: auto;
          grid-gap: 5px;
        }
        .arrow-positive {
          flex-direction: column-reverse;
        }
        .arrow-negative {
          flex-direction: column-reverse;  
        }
        & > div > .icon-arrow {
          width: 100%;
          text-align: center;
          font-size: 8px;
          font-weight: 800;
        }
        & > div .text {
          margin: 0 auto;
          font-size: 12px;
        }
        .service {
          font-weight: 700;
          grid-template-rows: 1fr;
          grid-template-columns: 1fr 2fr;
          margin: 0;

          & > img {
            width: auto;
            max-width: 100%;
            max-height: 2rem;
            margin: auto;
          }
        }
        .service-sura {

          .text {
            text-align: start;
            margin-right: 35%;

            .consume-service {
              width: auto;
            }
            .consume-service-sura-energy {
              margin-left: .9rem;
            }
            .consume-service-sura-water {
              margin-left: .5rem;
            }
          }
          & > img {
            margin-left: 35%;
          }
        }
      }
      .cols-sura {
        grid-template-columns: 2fr 1fr;
      }
    }
  }

  /* Calification */
  .more-info {

    .calification {
      width: 100%;
      display: flex;
      flex-direction: column;
      background: linear-gradient(to bottom, var(--color-dark-primary), var(--color-dark-blue));
      padding: 1rem;
      box-shadow: 0px 1px 10px 2px rgb(0 0 0 / 45%);

      .header {
        display: grid;
        grid-template-columns: 3fr 1fr;
        margin-bottom: 1rem;

        .title {
          font-size: 14px;
          font-weight: 500;
          height: 1rem;
          position: relative;
          width: fit-content;

          .border-in-title {
            position: absolute;
            height: 6px;
            width: 105%;
            left: 0;
            top: 120%;
            background: linear-gradient(to right, var(--color-primary), #fff);
            border-radius: 2rem;
          }
        }
        .container-points {
          display: flex;
          text-align: end;
          align-items: center;
          justify-content: flex-end;
          grid-gap: 4px;
          font-weight: 600;

          & > span {
            font-size: 1rem;
            text-align: start;
            color: white;
            font-weight: 500;
            position: relative;

            & > span {
              position: absolute;
              bottom: 100%;
              left: 0;    
              right: 0;
              margin: 0 auto;
              text-align: center;
              font-size: 5px;
            }
          }
          .objective {
            font-size: 12px;
          }
          .border-separator {
            border-right: 2px solid white;
            height: 80%;
            transform: rotate(16deg);
          }
        }
        .icon {
          width: 20%;
          background-color: white;
          border-radius: 50%;
          padding: 3px;
        }
      }
      .icons-finish-start {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4px;
        margin-top: 1rem;

        & > img {
          height: 1.3rem;
        }
      }
      .bar-progress-month {
        width: 100%;
        position: relative;
        border-radius: 40px;
        padding: .4rem;
        background-color: white;
        margin-bottom: 1rem;

        .flag {
          position: absolute;
          bottom: 105%;
          left: 0;
          width: 1.3rem;
          transition: left 2s;
        }
        .bar-found {
          width: 100%;
          height: 1rem;
          border-radius: 40px;
          // background: linear-gradient(to right, #EE0A6B 0%, var(--color-primary) 100%);
          background-color: #b9b6b6e8;
        }
        .bar-progress {
          position: absolute;
          width: 0;
          top: 0;
          bottom: 0%;
          margin: auto 0;
          height: 1rem;
          border-radius: 40px;
          transition: width 2s;
        }
        .month {    
          position: absolute;
          width: 10%;
          display: flex;
          left: 0;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          grid-gap: 6px;
          transition: left 2s;

          .icon-circle {
            width: 2.5rem;
            border-radius: 50%;
            margin-left: -100%;
            // box-shadow: 0px 1px 8px 0 rgb(0 0 0 / 45%);
          }
          .text-month {
            height: 1.6rem;
            margin-left: -100%;
          }
        }
        .month-past {
          top: -.4rem;

          .icon-circle {
            opacity: 0.6;
          }
        }
        .month-current {
          bottom: -.4rem;

          .container-points-services {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            top: 110%;
            left: -2.4rem;
            background-color: white;
            border-radius: 20px;
            padding: 6px;
            opacity: 0;
            transition: opacity .5s;

            & > h4 {
              font-size: 9px;
              text-align: center;
            }
            .points-services {
              list-style: none;
              display: flex;
              grid-gap: 6px;
              justify-content: center;
              padding-inline-start: 0;
  
              & > li {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 10px;
              }
              & > li > img {
                height: 1.2rem;
              } 
            }
          }
          .active-points-services {
            opacity: 1;
            transition: opacity .5s;
          }
        }
      }
      &:hover .bar-progress-month .month .container-points-services {
        opacity: 1;
        transition: opacity .5s;
      }
    }
  }
  
  /* Data */
  .data {
    display: grid;

    .trees {
      width: 100%;
      margin: auto;
      border: 2px solid #218f28;
      border-radius: 20px;
      padding: 1rem;
      text-align: center;

      .title {
        font-size: 2rem;
        font-weight: 800;
        border-bottom: 2px solid #218f28;
        margin-bottom: .5rem;
        line-height: 28px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .text-title {
          font-size: 20px;
          line-height: 20px;
          font-weight: 400;
        }
      }
      & > img {
        margin-top: .5rem;
      }
      .container-quantity {
        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: center;

        .contain-quantity {

          .quantity {
            font-size: 4rem;
            font-weight: 800;
            margin: 0;
            margin-block-end: -8px;
          }
          .text {
            font-size: 20px;
          }
        }
        & > img {
          width: 90%;
          margin: 0 auto;
        }
      }
    }
    
    /* Graphic */
    .graphics {
      width: 100%;
      color: white;
      padding: 1rem;
      list-style: none;
      margin-top: 1rem;
      border: 1px solid var(--color-primary);

      & > li {
        display: grid;
        grid-template-columns: 3fr 5fr 1fr;
        margin-bottom: 4px;
        grid-gap: 6px;

        & > span:first-child {
          font-size: 10px;
          font-weight: 600;
        }
        .price {
          position: relative;
          font-size: 12px;
          font-weight: 600;
          z-index: 99;
        }
        .container-bar {
          display: flex;

          .bar-found {
            width: 100%;
            border-radius: 60px;
            background-color: white;
            // background: linear-gradient(circle to right, #ffffff 70%, #5cff00 30%) 0 0/74px 74px no-repeat;
            // background: radial-gradient(circle at center, #ffffff 70%, #5cff00 30%) 100px 0/74px 74px no-repeat;
          }
          .bar {
            width: 0;
            display: inline-block;
            height: 100%;
            position: absolute;
            left: 0;
            margin: auto;
            z-index: 9;
            border-radius: 60px;
            transition: width 2s;
          }
        }
      }
      .title {
        margin-bottom: 10px;
      }
      .description {
        text-align: center;
        font-weight: 500;
      }
    }
    .payment-total {
      display: flex;
      justify-content: space-around;
      padding: .1rem;
      border-radius: 40px;
      margin-top: .5rem;

      .text {
        font-weight: 800;
        color: black;
        display: flex;
        flex-direction: column;
      }
      .text > span {
        font-weight: 500;
      }
      .price {
        font-size: 26px;
        margin: auto 0 ;
        font-weight: 800;
        color: white;
      }
    }
    
    /* Warning */
    .warning {
      background-color: #dfce03;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 2rem;
      font-size: 14px;
      color: black;
      margin-bottom: 1rem;
      height: fit-content;

      .container-icon {
        background-color: white;
        display: inline;
        width: 40%;
        border-radius: 50%;
        padding: 0.5rem 1rem 0.5rem 1rem;
        margin-top: -2rem;
        margin-bottom: 1rem;

        .icon {
          width: 100%;
        }
      }
      .text {
        text-align: justify;

        &::-webkit-scrollbar {
          width: 0.5rem;
        }
        &::-webkit-scrollbar-track {
            background: rgb(199, 196, 196, .7);
        }
        &::-webkit-scrollbar-thumb {
            background: white;
            border-radius: 5px;
        }
      }
    }
  }
  
  /* Contact */
  .contact {

    & > img {
      display: grid;
      justify-content: center;
    }
    .text {
      border: 1px solid white;
      border-radius: 2rem;
      padding: .5rem 1rem;
      font-size: 12px;
    }
    .link-tips {
      text-align: center;
      text-decoration: none;
      display: inline-block;
      width: 100%;
      margin-top: .5rem;
    }
    & > img {
      width: 80%;
      margin: 0 auto;
      margin-top: 1rem;
    }
  }
}

/* Icon Dots */
.icon-dots-primary {
  display: none;
}

@media (min-width: 310px) {

  .report {

    .data-info {
      
      .card {

        .icon > img {
          width: 70%;
        }
      }
    }
  }

}

@media (min-width: 350px) {

  .report {

    .data-info {
      
      .card {

        .icon > img {
          width: 60%;
        }
      }
      .consume {
  
        .row {
          
          .header {
  
            .main-title {
              font-size: 8px;
            }
            .other-title {
              font-size: 5px;
            }
          }
        }
      }
    }


    .more-info {

      .calification {

        .header {

          .title {
            font-size: 1rem;
            height: 1rem;
          }
          .container-points {

            & > span {
              font-size: 20px;

              & > span {
                font-size: 7px;
              }
            }
            .objective {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

}

@media (min-width: 400px) {
  /* Card Main */
  .report {

    .data-info {

      .card {

        .description {

          .date {
            font-size: 18px;
          }
          .client {
            font-size: 22px;
          }
          .text {
            font-size: 20px;
          }
        }
        .icon > img {
          width: 70%;
        }
      }
      
      /* Plan */
      .info-plan {

        .plan {
          font-size: 12px;
        }
        .address {
          font-size: 8px;
        }
      }
    
      /* Services */
      .consume {
  
        .row {
          
          .header {
  
            .main-title {
              font-size: 9px;
            }
            .other-title {
              font-size: 6px;
            }
          }
        }
        .cols {
  
          .service {
  
            .text {
              font-size: 14px;
            }
          }
          & > div .text {
            font-size: 14px;
          }
        }
      }
    }
  
    /* Calification */
    .more-info {

      .calification {

        .header {

          .title {
            font-size: 20px;
            height: 20px;
          }
          .container-points {

            & > span {
              font-size: 24px;

              & > span {
                font-size: 8px;
              }
            }
            .objective {
              font-size: 20px;
            }
          }
        }
        .icons-finish-start {
          margin-bottom: 6px;
  
          & > img {
            height: 1.6rem;
          }
        }
        .bar-progress-month {
          
          .flag {
            width: 1.5rem;
          }
        }
      }
    }
  }
}

@media (min-width: 450px) {
  /* Card Main */
  .report {

    .data-info {

      .card {

        .icon > img {
          width: 55%;
        }
      }
    }
  }
}

@media (min-width: 500px) {
  /* Card Main */
  .report {

    .data-info {

      .card {

        .description {

          .date {
            font-size: 18px;
          }
          .client {
            font-size: 26px;
          }
          .text {
            font-size: 24px;
          }
        }
        .icon > img {
          width: 60%;
        }
      }

      /* Plan */
      .info-plan {

        .plan {
          font-size: 14px;
        }
        .address {
          font-size: 10px;
        }
      }
    
      /* Services */
      .consume {
  
        .row {
          
          .header {
  
            .main-title {
              font-size: 11px;
            }
            .other-title {
              font-size: 8px;
            }
          }
        }
        .cols {
  
          .service {
  
            .text {
              font-size: 16px;
  
              .consume-service {
                font-size: 9px;
              }
            }
          }
          & > div > .icon-arrow {
            font-size: 9px;
          }
          & > div .text {
            font-size: 16px;
          }
        }
      }
    }

    /* Calification */
    .more-info {

      .calification {

        .header {

          .title {
            font-size: 24px;
            height: 24px;
          }
        }
        .icons-finish-start {
          margin-bottom: 7px;
  
          & > img {
            height: 2rem;
          }
        }
        .bar-progress-month {
          
          .flag {
            width: 2rem;
          }
        }
      }
    }
  
    /* Data */
    .data {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
          "area1 area3 area3 area3"
          "area2 area2 area4 area4";
      grid-gap: 20px;

      .trees {
        grid-area: area1;
        height: 100%;
        display: grid;
        align-items: center;

        .container-quantity {
          grid-template-columns: 1fr;

          .contain-quantity {

            .quantity {
              font-size: 2rem;
            }
            .text {
              font-size: 14px;
            }
          }
        }
        .title {
          font-size: 22px;
          line-height: 20px;

          .text-title {
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
      .warning {
        grid-area: area2;
        margin-top: 0;

        .text {
          max-height: 150px;
          overflow-y: auto;
          width: 106%;
          padding: 0 3%;
        }
      }
      .container-graphics {
        grid-area: area3;

        .graphics {
          margin-top: 0;

          & > li > span:first-child {
            font-size: 12px;
          }
        }
      }
      .contact {
        grid-area: area4;
      }
    }
    .data-sura {
      grid-template-areas:
          "area3 area3 area3 area3"
          "area2 area2 area4 area4";
    }
  }
}

@media (min-width: 600px) {
  /* Card Main */
  .report {

    .card {
      border-radius: 2rem;
    }
    .data-info {

      .card {
        padding: 1rem;
        
        .description {

          .date {
            font-size: 20px;
          }
          .client {
            font-size: 30px;
          }
          .text {
            font-size: 28px;
          }
        }
        .icon {

          & > img {
            width: 60%;
          }
        }
      }

      /* Plan */
      .info-plan {

        .container-icon {
          width: 35px;
          height: 35px;
        }
        .plan {
          font-size: 18px;
        }
        .address {
          font-size: 12px;
        }
      }
    
      /* Services */
      .consume {

        .row {

          .header {

            .main-title {
              font-size: 18px;
            }
            .other-title {
              font-size: 10px;
            }
          }
          & > img {
            width: 50%;
          }
        }
        .cols {
  
          .service {
  
            .text {
              font-size: 18px;
  
              .consume-service {
                font-size: 10px;
              }
            }
          }
          & > div > .icon-arrow {
            font-size: 10px;
          }
          & > div .text {
            font-size: 18px;
          }
        }
      }
    }

    /* Calification */
    .more-info {

      .calification {
        padding: 1.5rem;

        .header {

          .title {
            font-size: 28px;
            height: 28px;
          }
          .container-points {

            & > span {
              font-size: 28px;

              & > span {
                font-size: 9px;
              }
            }
            .objective {
              font-size: 24px;
            }
          }
          .icon {
            width: 15%;
          }
        }
        .text-progress {
          grid-template-columns: 1fr 4fr 1fr;
        }
      }
    }
  }


}

@media (min-width: 650px) {
  /* Card Main */
  .report {

    .data-info {

      .card {

        .icon > img {
          width: 55%;
        }
      }
    }
  }
}

@media (min-width: 700px) {

  .report {
    
    /* Card Main */
    .data-info {

      .card {

        .description {

          .date {
            font-size: 22px;
          }
          .client {
            font-size: 34px;
          }
          .text {
            font-size: 32px;
          }
        }
        .icon {

          & > img {
            width: 60%;
          }
        }
      }

      /* Plan */
      .info-plan {

        .plan {
          font-size: 20px;
        }
        .address {
          font-size: 14px;
        }
      }
    
      /* Services */
      .consume {

        .cols {

          & > div {
            font-size: 20px;
          }
          .service > img {
            max-height: 2.7rem;
          }
        }
        .row {

          .expired-invoice {
            font-size: 10px;
          }
        }
      }
    }

    /* Calification */
    .more-info {

      .calification {

        .header {

          .title {
            font-size: 32px;
            height: 32px;
          }
          .container-points {

            & > span {
              font-size: 32px;

              & > span {
                font-size: 9px;
              }
            }
            .objective {
              font-size: 28px;
            }
          }
          .icon {
            width: 15%;
          }
        }
        .bar-progress-month {
  
          .month {
  
            .icon-circle {
              width: 3rem;
            }
          }
          .month-past {
            top: -.6rem;
          }
          .month-current {
            bottom: -.6rem;
            
            .container-points-services {
              left: -3rem;

              & > h4 {
                font-size: 10px;
              }
              .points-services {
    
                & > li {
                  font-size: 12px;
                }
                & > li > img {
                  height: 1.4rem;
                } 
              }
            }
          }
        }
        .text-progress {
          grid-template-columns: 1fr 6fr 1fr;
        }
      }
    }
  }

}

@media (min-width: 750px) {
  /* Card Main */
  .report {

    .data-info {

      .card {

        .icon > img {
          width: 55%;
        }
      }
    }
  }
}

@media (min-width: 800px) {

  .report {
    
    /* Card Main */
    .data-info {

      .card {

        .description {

          .date {
            font-size: 24px;
          }
          .client {
            font-size: 40px;
          }
          .text {
            font-size: 38px;
          }
        }
        .icon {

          & > img {
            width: 60%;
          }
        }
      }

      /* Plan */
      .info-plan {

        .plan {
          font-size: 22px;
        }
        .address {
          font-size: 15px;
          padding: 4px 16px;
        }
      }
    
      /* Services */
      .consume {

        .row {

          .header {

            .main-title {
              font-size: 20px;
            }
            .other-title {
              font-size: 12px;
              padding: 5px 0;
            }
          }
        }
        .cols {
  
          .service {
  
            .text {
              font-size: 20px;
  
              .consume-service {
                font-size: 12px;
              }
            }
          }
          & > div > .icon-arrow {
            font-size: 12px;
          }
          & > div .text {
            font-size: 20px;
          }
        }
      }
    }

    /* Calification */
    .more-info {

      .calification {

        .header {

          .title {
            font-size: 36px;
            height: 36px;
          }
          .icon {
            width: 15%;
          }
        }
        .bar-progress-month {
  
          .flag {
            height: 2.5rem;
          }
          .month {
  
            .icon-circle {
              width: 3.5rem;
            }
          }
          .month-past {
            top: -.9rem;
          }
          .month-current {
            bottom: -.9rem;
          }
        }
        .icons-finish-start {
          margin-bottom: 8px;
  
          & > img {
            height: 2.5rem;
          }
        }
        .text-progress {
          grid-template-columns: 1fr 6fr 1fr;
        }
      }
    }
  
    /* Data */
    .data {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas:
          "area1 area2 area2 area3 area3"
          "area4 area4 area4 area4 area4";
      grid-gap: 10px;

      .contact {
        display: grid;
        grid-template-columns: 4fr 3fr;

        & > img {
          width: 60%;
          margin: 0 auto;
        }
        .text {
          margin: auto 0;
        }
      }
    }
    .data-sura {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "area2 area2 area3 area3"
        "area4 area4 area4 area4";

      .contact {
        grid-template-columns: 3fr 3fr;
        align-items: flex-start;

        .link-tips {
          margin: 0;
        }
      }
    }
  }


}

@media (min-width: 900px) {
  /* Card Main */
  .report {

    .data-info {

      .info-plan {
        
        .container-icon {
          width: 45px;
          height: 45px;
        }
      }
      .card {

        .icon > img {
          width: 45%;
        }
      }
    }

    

    /* Calification */
    .more-info {

      .calification {

        .bar-progress-month {
  
          .month {
  
            .icon-circle {
              width: 3.8rem;
            }
          }
          .month-past {
            top: -1rem;
          }
          .month-current {
            bottom: -1rem;
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {

  .report {
    grid-template-columns: 3fr 4fr;

    & > article {
      justify-content: flex-start;
    }
    /* Card Main */
    .data-info {

      .card {

        .description {

          .date {
            font-size: 1rem;
          }
          .client {
            font-size: 26px;
          }
          .text {
            font-size: 22px;
          }
        }
        .icon {

          & > img {
            width: 60%;
          }
        }
      }

      /* Plan */
      .info-plan {

        .container-icon {
          width: 30px;
          height: 30px;
        }
        .plan {
          font-size: 14px;
        }
        .address {
          font-size: 10px;
          padding: 3px 14px;
        }
      }
    
      /* Services */
      .consume {

        .row {

          .header {

            .main-title {
              font-size: 12px;
            }
            .other-title {
              font-size: 7px;
            }
          }
        }
        .cols {
  
          .service {
  
            .text {
              font-size: 18px;
  
              .consume-service {
                font-size: 10px;
              }
            }
          }
          & > div > .icon-arrow {
            font-size: 10px;
          }
          & > div .text {
            font-size: 18px;
          }
        }
      }
    }

    /* Calification */
    .more-info {

      .calification {

        .header {
          
          .container-points {
              
            .border-separator {
              border-right: 3px solid white;
            }
          }
        }
        .bar-progress-month {
  
          .month {
  
            .icon-circle {
              width: 2.5rem;
            }
          }
          .month-past {
            top: -.3rem;
          }
          .month-current {
            bottom: -.3rem;
          }
        }
      }
    }
  
    /* Data */
    .data {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
          "area1 area3 area3 area3"
          "area2 area2 area4 area4";
      grid-gap: 20px;

      .trees {

        .title {
          font-size: 12px;
          line-height: 12px;

          .text-title {
            font-size: 10px;
            line-height: 10px;
          }
        }
      }
      .warning {
        margin-bottom: 0;
      }
      .contact {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        grid-gap: 1rem;
        
        & > img {
          width: 60%;
          margin: 0 auto;
        }
        .text {
          margin: 0;
        }
      }
    }
    .data-sura {
      grid-template-areas:
        "area3 area3 area3 area3"
        "area2 area2 area4 area4";
    }
  }

  /* Icon Dots */
  .icon-dots-primary {
    display: block;
  }

}

@media (min-width: 1280px) {

  .report {
    
    /* Card Main */
    .data-info {
      margin-top: 22px;

      .card {

        .description {

          .date {
            font-size: 18px;
          }
          .client {
            font-size: 30px;
          }
          .text {
            font-size: 26px;
          }
        }
        .icon {

          & > img {
            width: 60%;
          }
        }
      }
    
      /* Plan */
      .info-plan {

        .plan {
          font-size: 16px;
        }
        .address {
          font-size: 12px;
        }
      }
    
      /* Services */
      .consume {

        .row {

          .header {

            .main-title {
              font-size: 16px;
            }
            .other-title {
              font-size: 8px;
            }
          }
        }
        .cols {
  
          .service {
  
            .text {
              font-size: 24px;
  
              .consume-service {
                font-size: 13px;
              }
            }
          }
          & > div > .icon-arrow {
            font-size: 12px;
          }
          & > div .text {
            font-size: 22px;
          }
        }
      }
    }

    /* Calification */
    .more-info {

      .calification {

        .bar-progress-month {
  
          .flag {
            height: 3rem;
          }
          .month {
  
            .icon-circle {
              width: 3rem;
            }
          }
          .month-past {
            top: -.6rem;
          }
          .month-current {
            bottom: -.6rem;
          }
        }
      }
    }

    /* Data */
    .data {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-template-areas:
          "area1 area2 area2 area3 area3"
          "area4 area4 area4 area4 area4";
      grid-gap: 10px;

      .trees {

        .title {
          font-size: 14px;
          line-height: 12px;

          .text-title {
            font-size: 10px;
            line-height: 10px;
          }
        }
      }
      .warning {
        font-size: 12px;
      }
      .contact {
        display: grid;
        grid-template-columns: 4fr 3fr;

        & > img {
          width: 60%;
          margin: 0 auto;
        }
        .text {
          margin: auto 0;
        }
      }
    }
    .data-sura {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "area2 area2 area3 area3"
        "area4 area4 area4 area4";

      .contact {
        grid-template-columns: 3fr 3fr;

        .link-tips {
          margin: 0;
        }
      }
    }
  }

}

@media (min-width: 1400px) {

  .report {
    
    /* Card Main */
    .data-info {

      .card {

        .description {

          .date {
            font-size: 20px;
          }
          .client {
            font-size: 34px;
          }
          .text {
            font-size: 30px;
          }
        }
      }

      /* Plan */
      .info-plan {

        .container-icon {
          width: 40px;
          height: 40px;
        }
        .plan {
          font-size: 18px;
        }
        .address {
          font-size: 14px;
          padding: 4px 14px;
        }
      }
    
      /* Services */
      .consume {

        .row {

          .header {

            .main-title {
              font-size: 18px;
            }
            .other-title {
              font-size: 10px;
            }
          }
        }
        .cols {
  
          .service {
  
            .text {
              font-size: 26px;
  
              .consume-service {
                font-size: 14px;
              }
            }
          }
          & > div > .icon-arrow {
            font-size: 13px;
          }
          & > div .text {
            font-size: 24px;
          }
        }
      }
    }

    /* Calification */
    .more-info {

      .calification {

        .header {

          .title {
            font-size: 36px;
            height: 36px;
          }
        }
        .bar-progress-month {

          .month {

            .text-month {
              height: 2.8rem;
            }
          }
        }
      }
    }
  
    /* Data */
    .data {

      .warning {
        font-size: 13px;
      }
    }
  }

}

@media (min-width: 1600px) {

  .report {
    padding: 2rem;

    /* Card Main */
    .data-info {

      .card {

        .description {

          .date {
            font-size: 22px;
          }
          .client {
            font-size: 38px;
          }
          .text {
            font-size: 34px;
          }
        }
      }

      /* Plan */
      .info-plan {

        .plan {
          font-size: 20px;
        }
        .address {
          font-size: 15px;
        }
      }
    
      /* Services */
      .consume {

        .row {

          .header {

            .main-title {
              font-size: 20px;
            }
            .other-title {
              font-size: 11px;
              padding: 6px 0;
            }
          }
        }
        .cols {
  
          .service {
  
            .text {
  
              .consume-service {
                font-size: 15px;
              }
            }
          }
          & > div > .icon-arrow {
            font-size: 14px;
          }
          & > div .text {
            font-size: 26px;
          }
        }
      }
    }
  
  
    /* Calification */
    .more-info {

      .calification {

        .header {

          .title {
            font-size: 42px;
            height: 42px;
          }
          .container-points {

            & > span {
              font-size: 36px;

              & > span {
                font-size: 11px;
              }
            }
            .objective {
              font-size: 32px;
            }
          }
        }
        .bar-progress-month {

          .month {
            
            .icon-circle {
              width: 3.5rem;
            }
            .text-month {
              height: 3.2rem;
            }
          }
          .month-past {
            top: -.9rem;
          }
          .month-current {
            bottom: -.9rem;
          }
        }
      }
    }
  
    /* Data */
    .data {

      .trees {
        
        .title {
          font-size: 16px;
          line-height: 14px;

          .text-title {
            font-size: 12px;
            line-height: 12px;
          }
        }
      }
      .warning {
        font-size: 14px;

        .container-icon {
          width: 35%;
          margin-top: -3rem;
        }
      }
    }
  }

}

@media (min-width: 1800px) {

  .report {

    .data-info {
      
      /* Plan */
      .info-plan {

        .container-icon {
          width: 50px;
          height: 50px;
        }
      }
    }

    /* Calification */
    .more-info {

      .calification {

        .bar-progress-month {
  
          .flag {
            height: 3.5rem;
          }
          .month {
  
            .icon-circle {
              width: 3.9rem;
            }
          }
          .month-past {
            top: -1.1rem;
          }
          .month-current {
            bottom: -1.1rem;
            
            .container-points-services {
              left: -4rem;

              & > h4 {
                font-size: 14px;
              }
              .points-services {
    
                & > li {
                  font-size: 16px;
                }
                & > li > img {
                  height: 2rem;
                } 
              }
            }
          }
        }
      }
    }
  }

}

@media (min-width: 1900px) {

  .report {
    padding: 3rem;

    /* Card Main */
    .card {
      padding: 2rem !important;
    }
    .data-info {

      .card {

        .description {

          .date {
            font-size: 24px;
          }
          .client {
            font-size: 42px;
          }
          .text {
            font-size: 38px;
          }
        }
      }

      /* Plan */
      .info-plan {

        .plan {
          font-size: 24px;
        }
        .address {
          font-size: 17px;
        }
      }
    
      /* Services */
      .consume {

        .row {

          .expired-invoice {
            font-size: 14px;
          }
          .header {

            .main-title {
              font-size: 22px;
            }
            .other-title {
              font-size: 13px;
            }
          }
          & > img {
            width: 60%;
          }
        }
        .cols {
  
          .service {
  
            .text {
              font-size: 28px;
  
              .consume-service {
                font-size: 18px;
              }
            }
          }
          & > div > img {
            max-height: 3.5rem;
          }
          & > div > .icon-arrow {
            font-size: 16px;
          }
          & > div .text {
            font-size: 26px;
          }
        }
      }
    }
  
    /* Calification */
    .more-info {

      .calification {

        .header {

          .title {
            font-size: 54px;
            height: 54px;
          }
        }
        .bar-progress-month {

          .bar {
            height: 2rem;
          }
          .month {

            .icon-circle {
              width: 5.5rem;
            }
            .text-month {
              height: 3.4rem;
            }
          }
          .month-past {
            top: -1.5rem;
  
            .icon-circle {
              opacity: 0.6;
            }
          }
          .month-current {
            bottom: -1.5rem;
          }
        }
      }
    }
  
    /* Data */
    .data {
      margin-top: 2rem;

      .trees {

        .title {
          font-size: 26px;
          line-height: 24px;

          .text-title {
            font-size: 18px;
            line-height: 18px;
          }
        }
        .container-quantity {

          .contain-quantity {

            .quantity {
              font-size: 4rem;
            }
            .text {
              font-size: 24px;
              margin-bottom: 1rem;
            }
          }
        }
      }
      .warning {
        font-size: 1rem;
        padding: 1rem;

        .container-icon {
          width: 30%;
        }
        .text {
          max-height: 300px;
        }
      }
      .graphics {

        & > li {

          & > span:first-child {
            font-size: 15px;
          }
        }
      }
      .payment-total {

        .text {
          font-size: 22px;
        }
        .price {
          font-size: 2rem;
        }
      }
      .contact {
        margin-top: 1rem;

        .text {
          font-size: 1.3rem;
        }
      }
    }
  }

}